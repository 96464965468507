"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
require("@gibme/web-ui");
const finance_1 = require("@gibme/finance");
$(() => __awaiter(void 0, void 0, void 0, function* () {
    const table = $('#amortization_table').dt({
        fixedHeader: true,
        paging: false,
        searching: false,
        responsive: true,
        language: {
            emptyTable: 'Enter values to calculate table'
        },
        buttons: ['pdf'],
        columnDefs: [
            {
                targets: [0, 1],
                orderable: false
            },
            {
                targets: [2, 3, 4, 5, 6, 7],
                orderable: false,
                className: 'text-end',
                render: (record, type) => {
                    if (type === 'display') {
                        return $('<div class="row">')
                            .append($('<div class="col-3">'))
                            .append($('<div class="col-1 text-end">').text('$'))
                            .append($('<div class="col text-end">').text($.numeral(record).format('0,0.00')))
                            .fullHTML();
                    }
                    return record;
                }
            }
        ],
        order: [[0, 'asc']]
    });
    table.draw()
        .columns.adjust()
        .responsive.recalc();
    const _principal = $('#principal');
    const _interest_rate = $('#interest_rate');
    const _term_years = $('#term_years');
    const _start_date = $('#start_date');
    const _extra_payment = $('#extra_payment');
    const _extra_payment_start = $('#extra_payment_start');
    const _total_payments = $('#total_payments');
    const _total_amount = $('#total_amount');
    const _total_interest_pct = $('#total_interest_pct');
    const _interest_saved = $('#interest_saved');
    const _payments_saved = $('#payments_saved');
    {
        const utc = $.moment().utc().local();
        _start_date.val(utc.format('YYYY-MM-DD'));
    }
    const get_number = (elem) => {
        const value = elem.val() || '';
        return parseFloat(value.replace(/[[^\d.-]]/g, '') || '0') || 0;
    };
    const calculate = () => __awaiter(void 0, void 0, void 0, function* () {
        const elem = $(document.body);
        elem.overlay('show');
        yield $.sleep(1000);
        const principal = get_number(_principal);
        const interest_rate = get_number(_interest_rate);
        const months = get_number(_term_years) * 12;
        const start_date = _start_date.val() || $.moment().utc().local().format('YYYY-MM-DD');
        const extra_payment = get_number(_extra_payment);
        const extra_payment_start = get_number(_extra_payment_start);
        const amt_table = (0, finance_1.calculate_amortization_table)(principal, interest_rate, months, [{
                amount: extra_payment,
                month: (extra_payment_start === 0) ? 1 : extra_payment_start,
                fill: true
            }]);
        table.clear().draw();
        for (const row of amt_table) {
            const date = $.moment(start_date)
                .add(row.month - 1, 'M')
                .format('YYYY-MM-DD');
            table.row.add([
                row.month,
                date,
                row.payment,
                row.principal,
                row.extra_principal,
                row.interest,
                row.interest_paid,
                row.balance
            ]);
        }
        const loan = (0, finance_1.calculate_amortization_loan)(principal, interest_rate, months, [{
                amount: extra_payment,
                month: (extra_payment_start === 0) ? 1 : extra_payment_start,
                fill: true
            }]);
        _total_payments.val(loan.months);
        _total_amount.val($.numeral(loan.total_amount).format('0,0.00'));
        _total_interest_pct.val($.numeral(loan.interest * 100).format('0,0.0000'));
        _interest_saved.val($.numeral(loan.interest_saved * 100).format('0,0.00'));
        _payments_saved.val(loan.months_saved);
        table.draw()
            .columns.adjust();
        $.localStorage().set('cached', {
            start_date,
            principal,
            interest_rate,
            term_years: get_number(_term_years),
            extra_payment,
            extra_payment_start
        });
        elem.overlay('hide');
    });
    {
        const cached = $.localStorage().get('cached');
        if (cached) {
            _start_date.val(cached.start_date);
            _principal.val(cached.principal);
            _interest_rate.val(cached.interest_rate);
            _term_years.val(cached.term_years);
            _extra_payment.val(cached.extra_payment);
            _extra_payment_start.val(cached.extra_payment_start);
        }
    }
    $('#calculate').on('click', () => calculate());
    yield calculate();
}));
